import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import {DefaultLayout, LoginLayout} from "./layouts";


// Route Views
import UserProfileLite from "./views/UserProfileLite";
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import Components from "./views/components-overview";
import ChangePasswrod from './views/changePassword';
import PickTest from './views/PickTest';
import Testdata from './views/TestPageData';
import Success from './views/SuccessPage'

export default [
  
  {
    path: "/",
    layout: DefaultLayout,
    exact: true,
    component: PickTest,
    component: () => <Redirect to="https://candidate.n2ns.app/login" />
  },

  // {
  //   path: "/dashboard",
  //   layout: DefaultLayout,
  //   component: Dashboard
  // },
 
  // {
  //   path: "/edit-user-profile",
  //   layout: DefaultLayout,
  //   component: UserProfileLite
  // },
  // {
  //   path: "/settings",
  //   layout: DefaultLayout,
  //   component: ChangePasswrod
  // },
 
  // {
  //   path: "/login",
  //   layout: LoginLayout,
  //   component: Login
  // },
  
 
  // {
  //   path: "/components",
  //   layout: DefaultLayout,
  //   component: Components
  // },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: Components
  // },
 
  {
    path: "/picktest",
    layout: DefaultLayout,
    component: PickTest
  },
  {
    path: "/:category/:test",
    layout: DefaultLayout,
    component: Testdata
  },
  {
    path: "/success",
    layout: DefaultLayout,
    component: Success
  },
];
