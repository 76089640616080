import {
  GET_SUBSCRIBER_LIST,
  GET_SUBSCRIBER_LIST_SUCCESS,
  GET_SUBSCRIBER_LIST_ERROR } from './../actions';

export const getSubscribersList = (pageNo,perPage,recuiter) => ({
  type: GET_SUBSCRIBER_LIST,
  payload:{pageNo,perPage,recuiter}
});

export const getSubscribersListSuccess = (subscribers) => ({
  type: GET_SUBSCRIBER_LIST_SUCCESS,
  payload: subscribers
});

export const getSubscribersListError = (message) => ({
  type: GET_SUBSCRIBER_LIST_ERROR,
  payload:  message

});
