import axios from './../../utils/Api'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {GET_SUBSCRIBER_LIST} from './../actions';
import qs from 'qs';
import {
  getSubscribersListSuccess,
  getSubscribersListError,
} from './actions';


export function* watchGetSubs() {
  yield takeEvery(GET_SUBSCRIBER_LIST, getSubsNow);
}
 
// const getrecruiter = (state) => state.Auth.authUser.email
const getSubsNowAsync = async (pageNo,perPage,recuiter) =>
  await axios.get(`subscriber/list?pageNo=${pageNo}&perPage=${perPage}&recruiter=${recuiter}`
  ).then(({data}) => {
    console.log("Sub data",data);
    return data;
  }).catch(function (error) {
    console.log(error);
    return error.response.data.message;
  });

function* getSubsNow({payload}) {
  try {

   
    const {pageNo,perPage,recuiter}=payload;
    const res = recuiter.charAt(0).toUpperCase() + recuiter.slice(1)
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
    const response = yield call(getSubsNowAsync,pageNo,perPage,res);
    if (response.status) {
      yield put(getSubscribersListSuccess(response));
    } else {
      yield put(getSubscribersListError(response));
    }
  } catch (error) {
    console.log(error);
    yield put(getSubscribersListError(error.message));

  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSubs),
  ]);
}
