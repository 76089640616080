 import axios from '../../utils/Api'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {APPLICATION_POST} from '../actions';
import qs from 'qs';
import {
  applicationSubmitSuccess,
  applicationSubmitError,
} from './actions';
// import axios from 'axios';


var config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("ats_user_token")}`,
    "Content-Type": "application/json"
  }
};
var  uri = `https://api.n2ns.app/microservices/api/job/calculateJobByJSON`;


export function* watchApplicationSubmit() {
  yield takeEvery(APPLICATION_POST, ApplicationSubmitNow);
}
// http://192.168.100.110:3000/api/job/calculateJobByJSON

  

const ApplicationSubmitNowAsync = async (values) =>
await axios
  .post(uri, values, config).then((response) => {
    console.log(response);
    return response;
  }).catch(function (error) {
    console.log(error);
    return error.response;
  });


function* ApplicationSubmitNow({payload}) {
  console.log(payload.values);
  // localStorage.setItem("email",email);
  if(payload == null){
    console.log("aaaaaaaa")
  }
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
    const response = yield call(ApplicationSubmitNowAsync, payload.values);
    if (response.status) {
      console.log("response",response)
      yield put(applicationSubmitSuccess(response));
    } else {
      yield put(applicationSubmitError(response.message));
    }
  } catch (error) {
    console.log(error);
    yield put(applicationSubmitError("Network Error"));

  }
}


export default function* rootSaga() {
  yield all([
    fork(watchApplicationSubmit),
  ]);
}
