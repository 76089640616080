import axios from './../../utils/Api'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
  GET_JOB_LIST,
  GET_UNKNOWN_SUBMODS_LIST,
  ADD_VALID_SUBMOD
} from './../actions';
import qs from 'qs';
import {
  getJobsListSuccess,
  getJobsListError,
  getUnknownSubModsSuccess,
  getUnknownSubModsError,
  addValidSubmodSuccess,
  addValidSubmodError
} from './actions';


export function* watchGetJobs() {
  yield takeEvery(GET_JOB_LIST, getJobsNow);
}

export function* watchGetUnknownSubmods() {
  yield takeEvery(GET_UNKNOWN_SUBMODS_LIST, getUnknownSubmods);
}

export function* watchAddValidSubmod() {
  yield takeEvery(ADD_VALID_SUBMOD, addValidSubmodd);
}

const addValidSubmodAsync = async (values) =>
  await axios.post('submodality/addValidated', qs.stringify(values)
  ).then((response) => {
    console.log(response);
    return response;
  }).catch(function (error) {
    console.log(error);
    return error.response;
  });


function* addValidSubmodd({payload}) {
  console.log(payload.values);
  // localStorage.setItem("email",email);
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
    const response = yield call(addValidSubmodAsync, payload.values);
    if (response.status) {
      yield put(addValidSubmodSuccess(response.message));
    } else {
      yield put(addValidSubmodError(response.message));
    }
  } catch (error) {
    console.log(error);
    yield put(addValidSubmodError("Network Error"));

  }
}

const getJobsNowAsync = async () =>
  await axios.get('job/list'
  ).then(({data}) => {
    console.log(data);
    return data;
  }).catch(function (error) {
    console.log(error)
    return error.response.data.message;
  });

function* getJobsNow() {
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
    const response = yield call(getJobsNowAsync);
    if (response.status) {
      yield put(getJobsListSuccess(response.data));
    } else {
      yield put(getJobsListError(response));
    }
  } catch (error) {
    console.log(error);
    yield put(getJobsListError("Network Error"));

  }
}

const getUnknownSubmodsAsync = async () =>
  await axios.get('submodality/getUnknownSubMods'
  ).then(({data}) => {
    console.log(data);
    return data;
  }).catch(function (error) {
    console.log(error)
    return error.response.data.message;
  });

function* getUnknownSubmods() {
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
    const response = yield call(getUnknownSubmodsAsync);
    if (response.status) {
      yield put(getUnknownSubModsSuccess(response.data));
    } else {
      yield put(getUnknownSubModsError(response));
    }
  } catch (error) {
    console.log(error);
    yield put(getUnknownSubModsError("Network Error"));

  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetJobs),
    fork(watchGetUnknownSubmods),
    fork(watchAddValidSubmod),
  ]);
}
