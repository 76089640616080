import axios from './../../utils/Api';
import {setCookie} from '../../auth/cookie'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {LOGIN_USER, CHANGE_PASSWORD, GET_USER_PROFILE} from './../actions';
import qs from 'qs';
import {
  loginUserSuccess,
  loginUserError,
  changePasswordResolved,
  getProfileSuccess
} from './actions';

import {eraseCookie} from '../../auth/cookie'

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  await axios.post('auth/login', qs.stringify({
      email: email,
      password: password,
    })
  ).then(({data}) => {
    console.log(data);
    return data;
  }).catch(function (error) {
    console.log(error)
    return error.response.data.message;
  });


function* loginWithEmailPassword({payload}) {
  console.log(payload);
  const {email, password} = payload.user;
  const {history} = payload;
  // localStorage.setItem("email",email);
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    console.log(loginUser);
    if (loginUser.status) {
      localStorage.setItem("ats_user_token", loginUser.data.token);
      localStorage.setItem("recuiter", loginUser.data.firstName);
      localStorage.setItem("recuiterLastName", loginUser.data.lastName);
      setCookie('jwt',loginUser.data.token,7)
      axios.defaults.headers.common['Authorization'] = "Bearer " + loginUser.data.token;
      console.log('loginUser',loginUser.data);
      yield put(loginUserSuccess(loginUser.data));
      history.push(`/dashboard`);
    } else {
      yield put(loginUserError(loginUser));
      
    }
  } catch (error) {
    console.log(error);
    yield put(loginUserError("Network Error"));

  }
}




export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePasswordNow);
}

const changePasswordNowAsync = async (currentPassword, newPassword) =>
  await axios.post('auth/change-password', qs.stringify({
    currentPassword: currentPassword,
      newPassword: newPassword,
    })
  ).then(({data}) => {
    console.log('res',data);
    return data;
  }).catch(function (error) {
    return error.response.data.message;
  });

function* changePasswordNow({payload}) {
  console.log(payload);
  const {currentPassword, newPassword, retypePassword} = payload.data;

  try {
    if(newPassword != retypePassword){
     yield put(changePasswordResolved('Retype Password is Not Correct', 'danger'))
    }else{
      axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
      const change = yield call(changePasswordNowAsync, currentPassword, newPassword);
      
        if(change.status){
          yield put(changePasswordResolved(change.message, 'success'))
        }
        else{
          yield put(changePasswordResolved(change, 'danger'))
        }
    }
   
  } catch (error) {
    console.log(error);
    yield put(changePasswordResolved('Something went wrong', 'danger'))

  }
}
export function* watchGetProfile() {
  yield takeEvery(GET_USER_PROFILE, getProfileNow);
}

const getProfileNowAsync = async () =>
  await axios.get('/profile' ).then(({data}) => {
    console.log('res',data);
    return data;
  }).catch(function (error) {
    console.log('res', error.response.status);
    return {status:error.response.status, message:error.response.message};
  });

  const  onLogOut =async ()=>{
    await eraseCookie('jwt')
    window.location.reload()
  }

function* getProfileNow() {
  console.log('res');
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
   const profile= yield call(getProfileNowAsync)

   if(profile.status == 401){
     console.log("roolaa")
     const logout = yield call(onLogOut)
     
   }
   if(profile.status){
     yield put(getProfileSuccess(profile.data))
   }
    
   
  } catch (error) {
    console.log(error);

  }
}


export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchChangePassword),
    fork(watchGetProfile)
  ]);
}
