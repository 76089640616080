import {
  GET_JOB_LIST,
  GET_JOB_LIST_SUCCESS,
  GET_JOB_LIST_ERROR,
  GET_UNKNOWN_SUBMODS_LIST,
  GET_UNKNOWN_SUBMODS_LIST_SUCCESS,
  GET_UNKNOWN_SUBMODS_LIST_ERROR,
  ADD_VALID_SUBMOD,
  ADD_VALID_SUBMOD_SUCCESS,
  ADD_VALID_SUBMOD_ERROR
} from './../actions';


const initState = {
  openJobs: [],
  unknownSubmods: [],
  error: false,
  message: '',
  loading: false
}


export default (state = initState, action) => {
  switch (action.type) {
    case GET_JOB_LIST:
      return {...state, loading: true, error: ''};
    case GET_JOB_LIST_SUCCESS:
      return {...state, loading: false, openJobs: action.payload};
    case GET_JOB_LIST_ERROR:
      return {...state, loading: false, message: action.payload, error: true};

    case ADD_VALID_SUBMOD:
      return {...state, loading: true, error: '', message:''};
    case ADD_VALID_SUBMOD_SUCCESS:
      return {...state, loading: false, message:"Validated Successfully", error: false};
    case ADD_VALID_SUBMOD_ERROR:
      return {...state, loading: false, message: "Validated Modaility Went Wrong", error: true};

    case GET_UNKNOWN_SUBMODS_LIST:
      return {...state, loading: true, error: ''};
    case GET_UNKNOWN_SUBMODS_LIST_SUCCESS:
      return {...state, loading: false, unknownSubmods: action.payload};
    case GET_UNKNOWN_SUBMODS_LIST_ERROR:
      return {...state, loading: false, message: action.payload, error: true};

    default:
      return {...state};
  }
}
