import {
  GET_APPLICANTS_LIST,
  GET_APPLICANTS_LIST_SUCCESS,
  GET_APPLICANTS_LIST_ERROR,
  NOTES_POST,
NOTES_POST_SUCCESS,
NOTES_POST_ERROR
} from './../actions';

const initState = {
  applicants: [],
  error: false,
  message: '',
  loading: false
}
export default (state = initState, action) => {
  switch (action.type) {
    case GET_APPLICANTS_LIST:
      return {...state, loading: true, error: ''};
    case GET_APPLICANTS_LIST_SUCCESS:
      return {...state, loading: false, applicants: action.payload.data, message: "get Applicants successfully"};
    case GET_APPLICANTS_LIST_ERROR:
      return {...state, loading: false, message: action.payload, error: true};

      case NOTES_POST:
      return {...state, loading: true, error: ''};
    case NOTES_POST_SUCCESS:
      return {...state, loading: false, applicants: action.payload.data, message: "Add Notes successfully"};
    case NOTES_POST_ERROR:
      return {...state, loading: false,  error: true,message:"Error! Add Notes"};
    default:
      return {...state};
  }
}
