import axios from 'axios';

export default axios.create({

   baseURL: `https://api.n2ns.app/microservices/api/`,
  // baseURL: `https://realtism.com/n2n-ats/api/`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});



