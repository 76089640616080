import React, {Component,createRef} from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  FormCheckbox,
  FormSelect,
  Button,
  Alert
} from "shards-react";
import {connect} from "react-redux";
import {loginUser, changePassword,clearAlert} from "../../redux/auth/actions";
import {withRouter} from "react-router-dom";
import { store } from 'react-notifications-component';
import Bcrypt from 'bcryptjs'

class ChangePasswordForm extends Component {

  constructor(props) {
    super(props);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.ChangePasswordForm = createRef()
    this.state = {
        value: null,
        msg:null,
        alertType:null
    };
  }

  componentDidUpdate() {
 if(this.props.alertMessageType === 'success'){
    document.getElementById('changePasswordForm').reset();
    
 }
  }

componentDidMount(){
    this.props.clearAlert()
}

  onChangePassword = (e) => {
    e.preventDefault();
    const {currentPassword, newPassword, retypePassword} = e.target;
    const data = {
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
        retypePassword:retypePassword.value
      };
    this.props.changePassword(data);
  };

  onChangeInput = () =>{
      this.props.clearAlert()
  }

  render() {
    const {alertMessage,alertMessageType} = this.props
    return (
      <ListGroup>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
             {alertMessage && PasswordAlert(alertMessage, alertMessageType)}
              <Form onSubmit={this.onChangePassword} id='changePasswordForm'>
                <Row form>
                  <Col className="form-group">
                    <label htmlFor="currentPassword">Current Password</label>
                    <FormInput
                      name="currentPassword"
                      id="currentPassword"
                      type="password"
                      placeholder="Current Password"
                      required
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                <Col className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <FormInput
                      name="newPassword"
                      id="newPassword"
                      type="password"
                      placeholder="New Password"
                      required
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                <Col className="form-group">
                    <label htmlFor="retypePassword">Re-type Password</label>
                    <FormInput
                      name="retypePassword"
                      id="retypePassword"
                      type="password"
                      placeholder="Re-type Password"
                      required
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Button className="mt-2" type="submit">Submit</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    );
  }
}

const mapStateToProps = ({Auth}) => {
  const {alertMessage,alertMessageType} = Auth;
  return {alertMessage,alertMessageType};
};

const PasswordAlert =(msg,type)=>{
return(
    <Alert theme={type}>
    {msg}
  </Alert>
)
}
export default withRouter(connect(
  mapStateToProps,
  {
    changePassword,
    clearAlert
  }
)(ChangePasswordForm));
