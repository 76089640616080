/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

/* JOB */
export const GET_JOB_LIST = "GET_JOB_LIST";
export const GET_JOB_LIST_SUCCESS = "GET_JOB_LIST_SUCCESS";
export const GET_JOB_LIST_ERROR = "GET_JOB_LIST_ERROR";

/* SUBMODS */
export const GET_UNKNOWN_SUBMODS_LIST = "GET_UNKNOWN_SUBMODS_LIST";
export const GET_UNKNOWN_SUBMODS_LIST_SUCCESS = "GET_UNKNOWN_SUBMODS_LIST_SUCCESS";
export const GET_UNKNOWN_SUBMODS_LIST_ERROR = "GET_UNKNOWN_SUBMODS_LIST_ERROR";

export const ADD_VALID_SUBMOD = "ADD_VALID_SUBMOD";
export const ADD_VALID_SUBMOD_SUCCESS = "ADD_VALID_SUBMOD_SUCCESS";
export const ADD_VALID_SUBMOD_ERROR = "ADD_VALID_SUBMOD_ERROR";


/* SUBSCRIBERS */
export const GET_SUBSCRIBER_LIST = "GET_SUBSCRIBER_LIST";
export const GET_SUBSCRIBER_LIST_SUCCESS = "GET_SUBSCRIBER_LIST_SUCCESS";
export const GET_SUBSCRIBER_LIST_ERROR = "GET_SUBSCRIBER_LIST_ERROR";


/*ChangePassword*/
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_RESOLVED = "CHANGE_PASSWORD_RESOLVED";
export const CLEAR_PASSWORD="CLEAR_PASSWORD"


/*GET PROFILE*/
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";


// Applicants
export const GET_APPLICANTS_LIST = "GET_APPLICANTS_LIST";
export const GET_APPLICANTS_LIST_SUCCESS = "GET_APPLICANTS_LIST_SUCCESS";
export const GET_APPLICANTS_LIST_ERROR = "GET_APPLICANTS_LIST_ERROR";

//Rate Calculation
export const RATE_CALCULATIONS = "RATE_CALCULATIONS";
export const RATE_CALCULATIONS_SUCCESS = "RATE_CALCULATIONS_SUCCESS";
export const RATE_CALCULATIONS_ERROR = "RATE_CALCULATIONS_ERROR";


//Application Submition
export const APPLICATION_POST = "APPLICATION_POST";
export const APPLICATION_POST_SUCCESS = "APPLICATION_POST_SUCCESS";
export const APPLICATION_POST_ERROR = "APPLICATION_POST_SUCCESS";

//Candidate Notes Post
export const NOTES_POST = "NOTES_POST";
export const NOTES_POST_SUCCESS = "NOTES_POST_SUCCESS";
export const NOTES_POST_ERROR = "NOTES_POST_ERROR";

//Post mySubmissions 
export const SUBMISSION_POST = "SUBMISSION_POST";
export const SUBMISSION_POST_SUCCESS = "SUBMISSION_POST_SUCCESS";
export const SUBMISSION_POST_ERROR = "SUBMISSION_POST_ERROR";

//Get Submissions
export const GET_SUBMISSION = "GET_SUBMISSION";
export const GET_SUBMISSION_SUCCESS = "GET_SUBMISSION_SUCCESS";
export const GET_SUBMISSION_ERROR = "GET_SUBMISSION_ERROR";

//Get history
export const GET_HISTORY_LIST = "GET_HISTORY_LIST";
export const GET_HISTORY_LIST_SUCCESS = "GET_HISTORY_LIST_SUCCESS";
export const GET_HISTORY_LIST_ERROR = "GET_HISTORY_LIST_ERROR";

//History Pdf
export const GET_HISTORY_PDF = "GET_HISTORY_PDF";
export const GET_HISTORY_PDF_SUCCESS = "GET_HISTORY_PDF_SUCCESS";
export const GET_HISTORY_PDF_ERROR = "GET_HISTORY_PDF_SUCCESS";

//Delete History 
export const DELETE_HISTORY = "DELETE_HISTORY";
export const DELETE_HISTORY_SUCCESS = "DELETE_HISTORY";
export const DELETE_HISTORY_ERROR = "DELETE_HISTORY";

export const GET_RECRUITER = "GET_RECRUITER";
export const GET_RECRUITER_SUCCESS = "GET_RECRUITER_SUCCESS";
export const GET_RECRUITER_ERROR = "GET_RECRUITER";

export const GET_OUT = "GET_OUT"

