import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
 GET_RECRUITER
} from "../actions";
import qs from "qs";
import {
  getRecruiterSuccess,
  getRecruiterError,
  
} from "./actions";
import download from "downloadjs";

import Moment from "moment";
export function* watchGetRecruiter() {
  yield takeEvery(GET_RECRUITER, getRecruiterNow);
}
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDNmYzhhN2UxN2ZiNjE5Mjc2NGNmNTkiLCJmaXJzdE5hbWUiOiJKZXJlbXkiLCJsYXN0TmFtZSI6IkpvbmVzIiwiZW1haWwiOiJqZXJlbXlAbnVyc2UybnVyc2VzdGFmZmluZy5jb20iLCJpYXQiOjE2MjM4NjUwMTUsImV4cCI6MTYyMzg3MjIxNX0.wuvJ5AHuxcScbmP3YxAItTgu6W4bxjnIQ38Lcp4MnSI";
  

  
// const getrecruiter = (state) => state.Auth.authUser.email
const getRecruiterNowAsync = async () =>
  await axios.get(`http://192.168.100.131:3000/api/auth/recruiters/all`, {
      headers: { Authorization: "Bearer " + token }
    })
    .then(({ data }) => {
      console.log(data)
      return data;
    })
    .catch(function(error) {
      console.log(error);
      return error.response.data;
    });

function* getRecruiterNow() {
  try {
    const response = yield call(getRecruiterNowAsync);
    if (response) {
      yield put(getRecruiterSuccess(response));
    } else {
      yield put(getRecruiterError(response));
    }
  } catch (error) {
    console.log(error);
    yield put(getRecruiterError(error.message));
  }
}


export default function* rootSaga() {
  yield all([
    fork(watchGetRecruiter)
    
  ]);
}
