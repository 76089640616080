import {
  GET_RECRUITER,
  GET_RECRUITER_SUCCESS,
  GET_RECRUITER_ERROR,
   } from '../actions';


  //get list of skills history
export const getRecruiter = () => ({
  type: GET_RECRUITER
});

export const getRecruiterSuccess = (history) => ({
  type: GET_RECRUITER_SUCCESS,
  payload: history
});

export const getRecruiterError = (message) => ({
  type: GET_RECRUITER_ERROR,
  payload:  message

});



