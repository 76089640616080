import React,{useEffect} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import PickTest from './views/PickTest';

import { loadReCaptcha } from 'react-recaptcha-google' ;


 const App =() => {
   useEffect(()=>{loadReCaptcha()},[])
return(
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <div>
      {/* {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={(props => {
              return (
                // <route.layout {...props}>
                  <route.component {...props} />
                // </route.layout>
              );
            })}
          />
        );
      })} */}
       <Route
          exact
          path="/:page"
          render={()=> window.location.assign("https://candidate.n2ns.app/login")}
          //  component={Application}
        />
        <Route
          exact
          path="/"
          render={()=> window.location.assign("https://candidate.n2ns.app/login")}
          //  component={Application}
        />
       {/* <Route 
            path='/picktest'
            exact
            render={props => <PickTest {...props} />}
            
          // component={TlcJobs}
          />
            */}
     
    </div>
  </Router>
)
    };
export default  App
