import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";

import {eraseCookie} from '../../../../auth/cookie'
import {getProfile} from '../../../../redux/auth/actions'

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  componentDidMount(){
this.props.getProfile()
  }


  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }
  async onLogOut(){
    await eraseCookie('jwt')
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <img
            className="user-avatar rounded-circle mr-2"
            src={ this.props.Profile && this.props.Profile.avatar ? 'https://api.n2ns.app/microservices/auth/avatars/' + this.props.Profile.avatar  : require('../../../../images/avatars/avatar-placeholder.png')}
            onError={(e)=>{ e.target.src=require('../../../../images/avatars/avatar-placeholder.png')}}
          />{" "}
          <span className="d-none d-md-inline-block">{this.props.Profile? this.props.Profile.firstName +" "+ this.props.Profile.lastName : " "}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="settings">
            <i className="material-icons">&#xE8B8;</i> Settings
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/" className="text-danger" onClick={()=>this.onLogOut()}>
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

const mapStateToProps = ({Auth})=>{
const {Profile} = Auth
return {Profile}
}

export default connect(mapStateToProps,{getProfile})(UserActions)