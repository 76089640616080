import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress
} from "shards-react";
import UserAvatarModal from './UserAvatarModal'


class UserDetails extends React.Component {
  constructor(props){
super();
this.state={
  imgModal:false
}
  }
  // console.log('userDetails',userDetails)
  // const [imgModal, setImgModal] = useState(false);
   
 
   openEditModal=()=>{
    this.setState({imgModal: !this.state.imgModal})
   }
   render(){
  return(
    <>
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded-circle clickable"
          src={ this.props.userDetails.avatar ? 'http://162.243.175.77:4000/avatars/' + this.props.userDetails.avatar  : require('./../../images/avatars/avatar-placeholder.png')}
        //  src={this.props.userDetails && this.props.userDetails.avatar? this.props.userDetails.avatar: require("./../../images/avatars/0.png")}
          alt={this.props.userDetails && this.props.userDetails.firstName? this.props.userDetails.firstName:""}
          width="110"
          onClick={this.openEditModal}
        />
      </div>
      <h4 className="mb-0">
        {this.props.userDetails && this.props.userDetails.firstName? this.props.userDetails.firstName:""}{" "}
        {this.props.userDetails && this.props.userDetails.lastName? this.props.userDetails.lastName:""}
      </h4>
      {/* <span className="text-muted d-block mb-2">{userDetails.jobTitle}</span> */}
    
    </CardHeader>
    {/* <ListGroup flush>
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <strong className="text-muted d-block mb-2">
            {userDetails.performanceReportTitle}
          </strong>
          <Progress
            className="progress-sm"
            value={userDetails.performanceReportValue}
          >
            <span className="progress-value">
              {userDetails.performanceReportValue}%
            </span>
          </Progress>
        </div>
      </ListGroupItem>
      <ListGroupItem className="p-4">
        <strong className="text-muted d-block mb-2">
          {userDetails.metaTitle}
        </strong>
        <span>{userDetails.metaValue}</span>
      </ListGroupItem>
    </ListGroup> */}
    <ListGroup flush>
     <ListGroupItem className="p-4">
        <strong className="text-muted d-block mb-2">
          {'Description'}
        </strong>
        <span> {this.props.userDetails && this.props.userDetails.description? this.props.userDetails.description:""}</span>
      </ListGroupItem>
    </ListGroup>
  
  </Card>
  <UserAvatarModal toggle={this.openEditModal} visible={this.state.imgModal} avatar={this.props.userDetails.avatar}/>
  </>
)}};

// UserDetails.propTypes = {
//   /**
//    * The user details object.
//    */
//   userDetails: PropTypes.object
// };

// UserDetails.defaultProps = {
//   userDetails:{
//     firstName:"",
//     lastName:"",
//     email:"",
//     avatar:"",
//     cell:"",
//     description:"",
//     office:""
//   }
//   // userDetails: {
//   //   name: "Megan Jones",
//   //   avatar: require("./../../images/avatars/0.png"),
//   //   jobTitle: "COO",
//   //   performanceReportTitle: "Workload",
//   //   performanceReportValue: 85,
//   //   metaTitle: "Description",
//   //   metaValue:
//   //     "In my work with Nurse 2 Nurse Staffing, I focus on creating a traveler-centered environment where our traveler's needs are always at the forefront! Some agencies forget that travelers are the lifeblood and representation of their companies, so it is my goal for travelers to know that they come first! My company involvement includes timekeeping, payroll processing, and AP/AR, but my real passion is matching healthcare professionals to the perfect assignments that suit their needs while genuinely getting to know all of our contract travelers! Candidates often tell me that they feel like I have become part of their family, and I frequently receive phone calls from travelers that have nothing to do with their assignments but just to chat! In addition to working with Nurse 2 Nurse Staffing, I am a homeschooling mom of two wonderful children, the wife of an Emergency Room Nurse, and my family loves to travel, especially to Pagosa Springs, CO! I would love to help you in your traveling career and look forward to hearing from you!"
//   // }
// };

export default UserDetails;
