export default function() {
  return [
    {
      title: "Dashboard",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/dashboard",
    },

   
    // {
    //   title: "Completed Checklists",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/checklists",
    // },
    
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },

    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    //  },

    
    
    {
      title: "Skills Test",
      htmlBefore: '<i class="material-icons">domain_verification</i>',
      to: "/picktest",
    },  
    
  ];
}
