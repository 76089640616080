import {
  APPLICATION_POST,
  APPLICATION_POST_SUCCESS,
  APPLICATION_POST_ERROR
} from '../actions';

const initState = {
  error: false,
  message: '',
  loading: false,
  ApplicationSubmit:[]
}
export default (state = initState, action) => {
  switch (action.type) {
    case APPLICATION_POST:
      return {...state, loading: true, error: '', message:''};
    case APPLICATION_POST_SUCCESS:
      return {...state, loading: false, ApplicationSubmit:action.payload.data.data, message:"Application Submitted Successfully", error: false};
    case APPLICATION_POST_ERROR:
      return {...state, loading: false, message: "Error! Application Submition", error: true};

    default:
      return {...state};
  }
}
