import {
  GET_SUBSCRIBER_LIST,
  GET_SUBSCRIBER_LIST_SUCCESS,
  GET_SUBSCRIBER_LIST_ERROR
} from './../actions';

const initState = {
  subscribers: [],
  error: false,
  message: '',
  loading: false
}
export default (state = initState, action) => {
  switch (action.type) {
    case GET_SUBSCRIBER_LIST:
      return {...state, loading: true, error: ''};
    case GET_SUBSCRIBER_LIST_SUCCESS:
      return {...state, loading: false, subscribers: action.payload.data, message: action.payload.message};
    case GET_SUBSCRIBER_LIST_ERROR:
      return {...state, loading: false, message: action.payload, error: true};

    default:
      return {...state};
  }
}
