import { all } from 'redux-saga/effects';

import authSagas from './auth/saga';
import jobsSagas from './jobs/saga';
import subsSagas from './subscribers/saga';
import applicantsSagas from './applicants/saga';
import rateCalculation from './rateCalculation/saga'
import applicationPost from './applicationPost/saga'
import mySubmissions from './mySubmissions/saga'
import history from './history/saga';
import recruiter from './Recruiter/saga'
export default function* rootSaga() {
  yield all([
    authSagas(),
    jobsSagas(),
    subsSagas(),
    applicantsSagas(),
    rateCalculation(),
    applicationPost(),
    mySubmissions(),
    history(),
    recruiter()
  ]);
}
