import React, { Component } from "react";
import GoogleAnalytics from "react-ga";
import { Redirect, Route } from "react-router";
import {checkCookie} from './auth/cookie'


GoogleAnalytics.initialize(process.env.REACT_APP_GAID || "UA-115105611-2");

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    GoogleAnalytics.set({
      page,
      ...options
    });
    GoogleAnalytics.pageview(page);
  };

  const Auth = ()=>{
    // if(localStorage.getItem('ats_user_token')) return true
    // else return false
    return checkCookie('jwt')
  }
  const BASENAME = process.env.REACT_APP_BASENAME || "";

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // console.log('nextProps', this.props.location.pathname) 
      // eslint-disable-next-line
     
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(`${BASENAME}${page}`);
    }

    componentDidUpdate(prevProps) {
      
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(`${BASENAME}${nextPage}`);
      }
    }

    render() {
      const authed = checkCookie('jwt')
      if(this.props.location.pathname != '/login'){
     return <Route
      render={(props) => authed === true
        ? <WrappedComponent {...this.props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />}
    else if(this.props.location.pathname == '/login' && authed){
     return <Route
     render={(props) =>  <Redirect to={{pathname: '/dashboard', state: {from: props.location}}} />}
   />
    }
    else{
    return <WrappedComponent {...this.props} />;
    }
      
    }
  };

  return HOC;
};

export default withTracker;
