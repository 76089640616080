import axios from './../../utils/Api'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {GET_SUBMISSION,SUBMISSION_POST} from './../actions';
import qs from 'qs';
import {
  getSubmissionsSuccess,
  getSubmissionsError,
  addSubmissionsSuccess,
  addSubmissionsError
} from './actions';


export function* watchGetSubmissions() {
  yield takeEvery(GET_SUBMISSION, getSubmissionsNow);
}
 
// const getrecruiter = (state) => state.Auth.authUser.email
const getSubmissionsNowAsync = async (pageNo,perPage,recuiter) =>
  await axios.get(`subscriber/list?pageNo=${pageNo}&perPage=${perPage}&recruiter=${recuiter}`
  ).then(({data}) => {
    console.log("Sub data",data);
    return data;
  }).catch(function (error) {
    console.log(error);
    return error.response.data.message;
  });

function* getSubmissionsNow({payload}) {
  try {

   
    const {pageNo,perPage,recuiter}=payload;
    const res = recuiter.charAt(0).toUpperCase() + recuiter.slice(1)
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
    const response = yield call(getSubmissionsNowAsync,pageNo,perPage,res);
    if (response.status) {
      yield put(getSubmissionsSuccess(response));
    } else {
      yield put(getSubmissionsError(response));
    }
  } catch (error) {
    console.log(error);
    yield put(getSubmissionsError(error.message));

  }
}


//Post Submissions
export function* watchAddSumissions() {
  yield takeEvery(SUBMISSION_POST, addSubmissionsNow);
}

const addSubmissionsNowAsync = async (values) =>
  await axios.post('submodality/addValidated', qs.stringify(values)
  ).then((response) => {
    console.log(response);
    return response;
  }).catch(function (error) {
    console.log(error);
    return error.response;
  });


function* addSubmissionsNow({payload}) {
  console.log(payload.values);
  // localStorage.setItem("email",email);
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
    const response = yield call(addSubmissionsNowAsync, payload.values);
    if (response.status) {
      yield put(addSubmissionsSuccess(response.message));
    } else {
      yield put(addSubmissionsError(response.message));
    }
  } catch (error) {
    console.log(error);
    yield put(addSubmissionsError("Network Error"));

  }
}


export default function* rootSaga() {
  yield all([
    fork(watchGetSubmissions),
    fork(watchAddSumissions)
  ]);
}
