import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "shards-react";
import UserAvatarEdit from "./UserAvatarEdit";

const UserAvatarModal = ({ toggle, visible ,avatar }) => {
  const closeModal=()=>{
    toggle(false);
    window.location.reload()
  }
  return (
    
    <div>
      {/*<Button onClick={toggle}>Edit Image</Button>*/}
      <Modal width="50%" effect="fadeInUp" open={visible} toggle={toggle}>
        <ModalHeader closeAriaLabel={"Close"}>Edit Image</ModalHeader>
        <ModalBody>
          <UserAvatarEdit Avatar={avatar}  closeModal = {closeModal} />
        </ModalBody>
      </Modal>
    </div>
  );
};
export default UserAvatarModal;
