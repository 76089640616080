import React, {Component} from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  FormCheckbox,
  FormSelect,
  Button
} from "shards-react";
import {connect} from "react-redux";
import {loginUser} from "../../redux/auth/actions";
import {withRouter} from "react-router-dom";
import { store } from 'react-notifications-component';

class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.onUserLogin = this.onUserLogin.bind(this);
    this.state = {value: null};
  }

  componentDidUpdate() {
    if (this.props.error) {
      store.addNotification({
        title: "Oops!",
        message: this.props.message,
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }
  onUserLogin = (e) => {
    e.preventDefault();
    console.log("this.props.history", this.props.history);
    const {email, password} = e.target;
    const values = {
      email: email.value,
      password: password.value
    };
    this.props.loginUser(values, this.props.history);

  };

  render() {

    return (
      <ListGroup>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form onSubmit={this.onUserLogin}>
                <Row form>
                  <Col className="form-group">
                    <label htmlFor="feEmailAddress">Email</label>
                    <FormInput
                      name="email"
                      id="feEmailAddress"
                      type="email"
                      placeholder="Email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label htmlFor="fePassword">Password</label>
                    <FormInput
                      name="password"
                      id="fePassword"
                      type="password"
                      placeholder="Password"
                    />
                  </Col>
                </Row>
                <Button className="mt-2" type="submit">Log In</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    );
  }
}

const mapStateToProps = ({Auth}) => {
  const {authUser, token, error, message, loading} = Auth;
  return {authUser, token, error, message, loading};
};

export default withRouter(connect(
  mapStateToProps,
  {
    loginUser
  }
)(LoginForm));
