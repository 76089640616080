import Auth from './auth/reducer';
import Jobs from './jobs/reducer';
import Subscribers from './subscribers/reducer';
import Applicants from './applicants/reducer';
import RateCalculation from './rateCalculation/reducer';
import ApplicationSubmit from './applicationPost/reducer';
import MySubmissions from './mySubmissions/reducer';
import History from './history/reducer'
import Recruiter from './Recruiter/reducer'

import {combineReducers} from 'redux';


const reducers = combineReducers({
  Auth,
  Jobs,
  Subscribers,
  Applicants,
  RateCalculation,
  ApplicationSubmit,
  MySubmissions,
  History
});

export default reducers;
