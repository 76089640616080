import React, { useState, useEffect } from "react";
import N2Nimage from "./images/nurse2nurse.png";
import data from "./data/categories.json";
import { Link } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha'
import { Container, Row, Col, Button } from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import Select from "react-select";

const PickTest = () => {
  const [listen, setListen] = useState("Advanced Practice");
  const [showNext, setShowNext] = useState(false);
  const [secondListen, setSecondListen] = useState();
  const [testDropDown, setTestDropDown] = useState([]);
  const [buttonDrop, setButtonDrop] = useState(false);
  const uri = encodeURIComponent(secondListen);
  useEffect(() => {
    if (listen) {
      setShowNext(true);
      setTestDropDown(
        data.categories.filter(f => f.category === listen)[0].tests
      );
    } else if (!listen) {
      setShowNext(false);
      setButtonDrop(true);
    }
  }, [listen]);

  useEffect(() => {
    if (secondListen) {
      setButtonDrop(true);
    } else if (!secondListen || !listen) {
      setButtonDrop(false);
    }
  }, [secondListen]);

  const [button, setButton] = useState(false);
  const googleIsAuthorized = () => {
    setButton(true);
    localStorage.setItem("isAuthorized", "trueVal");
  };
  return (
  
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
     
      <div className="outerBox">
        <section className="box">
          <img className="nurse2nurseHome" src={N2Nimage} />
          <p className="chooseTest">Please choose a Skills Assessment Test</p>
          <p className="homePagePTag mb-1">Category *</p>
          <select
            className="categorySelect"
            onChange={e => setListen(e.target.value)}
          >
            {data.categories.map((categoryName, index) => {
              return (
                <option key={index} value={categoryName.category}>
                  {categoryName.category}
                </option>
              );
            })}
          </select>

          {showNext && (
            <>
              <p className="homePagePTag mb-1 mt-4">Test *</p>
            
              <select
                className="testSelect"
                onChange={e => setSecondListen(e.target.value)}
              >
                <option value={null}></option>
                <li defaultChecked disabled>
                  Select Recruiter
                </li>
                {testDropDown.map((category, index) => {
                  return <option key={index}>{category}</option>;
                })}
              </select>
            </>
          )}
            {/* <Link to={`/${listen}/${uri}`}>
              <Button disabled={!buttonDrop} className="submit">Start</Button>
            </Link> */}
          
           
       {secondListen ? <div className="recaptcha">
               <ReCAPTCHA
                sitekey="6LdG8joaAAAAAJEEzqV_q08N8Tnrpb7lQVTbZdPD"
                onChange={googleIsAuthorized}
              />
            </div>:
            ""}
            {button ?  <Link to={`/${listen}/${uri}`}>
              <Button  className="submit">Start</Button>
            </Link> :""} 
           
          
        
        </section>
      </div>
    </Container>
  );
};

export default PickTest;
