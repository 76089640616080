import {
  GET_APPLICANTS_LIST,
  GET_APPLICANTS_LIST_SUCCESS,
  GET_APPLICANTS_LIST_ERROR,
NOTES_POST,
NOTES_POST_SUCCESS,
NOTES_POST_ERROR } from '../actions';

export const getApplicantsList = (recuiter) => ({
  type: GET_APPLICANTS_LIST,
  payload:{recuiter}
});

export const getApplicantsListSuccess = (applicants) => ({
  type: GET_APPLICANTS_LIST_SUCCESS,
  payload: applicants
});

export const getApplicantsListError = (message) => ({
  type: GET_APPLICANTS_LIST_ERROR,
  payload:  message

});

export const notesSubmit  = (values) => ({
  type: NOTES_POST,
  payload: {values}
});

export const notesSubmitSuccess = (data) => ({
  type: NOTES_POST_SUCCESS,
  payload: data
});
export const notesSubmitError = (data) => ({
  type: NOTES_POST_ERROR,
  payload:  data
});

