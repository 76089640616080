import React, { useState, useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { Redirect } from "react-router-dom";
// import { Button } from 'react-bootstrap'
import { Spinner } from "react-bootstrap";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  Button,
  Container,
  CardBody
} from "shards-react";
const FormSubmission = props => {
  
  return (
    <>
      {props.success && <Redirect to="/success" />}
      <section className="wrapper">
        <section className="recruiterInformation">
          <p className="recruiterEmail">Recruiter Name *</p>
          <form className="contactInformationForm" onSubmit={props.setSpinner}>
            <div className="recruiterEmailContainer">
              <select
                name="recruiterEmail"
                // value={props.contactInformation.recruiterEmail}
                onChange={e => props.handleChange(e)}
                className="recruiterAddress"
                required
              >
                <option selected disabled value="">
                  Select Recruiter
                </option>
                {props.recruiters.map(recruiter => {
                  return (
                    <option value={recruiter.email}>
                      {recruiter.firstName} {recruiter.lastName}
                    </option>
                  );
                })}
              </select>
            </div>
            {/*</section>*/}
            {/*<section className="apiCallBox">*/}
            <p className="contactInformation">Contact Information *</p>

            <Card small className="mb-4">
              <CardHeader
                className="border-bottom"
                style={{ margin: "0 auto" }}
              >
                <h6>Contact Information *</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form
                      // onSubmit={this.submitProfile}
                      >
                        <Row form>
                          {/* First Name */}
                          <Col lg="3" sm="12" className="form-group ">
                            <label className="font-weight-bold" htmlFor="City">
                              First Name *
                            </label>
                            <FormInput
                              required
                              name="firstName"
                              id="firstName"
                              placeholder="firstName"
                              onChange={e => props.handleChange(e)}
                              // onBlur={e => this.onChangeCity(e)}
                            />
                          </Col>
                          <Col lg="3" sm="12" className="form-group ">
                            <label className="font-weight-bold" htmlFor="City">
                              Last Name*
                            </label>
                            <FormInput
                              required
                              name="lastName"
                              id="lastName"
                              placeholder="lastName"
                              onChange={e => props.handleChange(e)}
                              // onBlur={e => this.onChangeCity(e)}
                            />
                          </Col>
                          <Col lg="3" sm="12" className="form-group ">
                            <label className="font-weight-bold" htmlFor="City">
                              Email *
                            </label>
                            <FormInput
                              required
                              name="nurseEmail"
                              id="nurseEmail"
                              placeholder="Email"
                              onChange={e => props.handleChange(e)}
                              // onBlur={e => this.onChangeCity(e)}
                            />
                          </Col>
                          <Col lg="3" sm="12" className="form-group ">
                            <label className="font-weight-bold" htmlFor="City">
                              Phone Number *
                            </label>
                            <FormInput
                              required
                              type="number"
                              name="phoneNumber"
                              id="phoneNumber"
                              placeholder="Number"
                              onChange={e => props.handleChange(e)}
                              
                            />
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>

         
            
            <p className="signature">Signature *</p>
            <section className="apiCallBox2">
              <section className="sigCanvas">
                <SignatureCanvas
                  backgroundColor="white"
                  penColor="black"
                  canvasProps={{ className: "siggCanvas" }}
                  ref={props.sigCanvas}
                />
                <section className="flexButtons">
                  <Button
                    type="button"
                    className="saveButton"
                    onClick={props.save}
                  >
                    Save *
                  </Button>
                  <Button
theme="warning"
                    className="clearButton ml-2"
                    onClick={props.clear}
                  >
                    Clear
                  </Button>
                </section>
              </section>
              <div className="finishSection">
                <p className="certification">
                  I certify this test was filled out to the best of my
                  knowledge.
                </p>
                {props.spinnerVal ? (
                  <Spinner variant="primary" animation="border" role="status">
                    <span></span>
                  </Spinner>
                ) : (
                  <Button
                    className="finish"
                    disabled={props.signatureCanvas === "val"}
                    type="submit"
                  >
                    Finish
                  </Button>
                )}
              </div>
            </section>
          </form>
        </section>
      </section>
    </>
  );
};

export default FormSubmission;
