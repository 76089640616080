import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_RESOLVED,
  CLEAR_PASSWORD,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_OUT
} from "./../actions";

const initState = {
  authUser: [],
  token: "",
  error: false,
  message: "",
  loading: false,
  alertMessage: null,
  alertMessageType: null,
  Profile: []
};
export default (state = initState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        authUser: action.payload.user,
        token: action.payload.user.token
      };
    case LOGIN_USER_ERROR:
      return { ...state, loading: false, message: action.payload, error: true };

    case CHANGE_PASSWORD:
      return { ...state, alertMessage: null, alertMessageType: null };
    case CHANGE_PASSWORD_RESOLVED:
      return {
        ...state,
        alertMessage: action.payload.msg,
        alertMessageType: action.payload.type
      };
    case CLEAR_PASSWORD:
      return { ...state, alertMessage: null, alertMessageType: null };
    case GET_USER_PROFILE_SUCCESS:
      return { ...state, Profile: action.payload.data };

    case GET_OUT:
      return {
        ...state,
        Profile: action.payload.data
      };
    default:
      return { ...state };
  }
};
