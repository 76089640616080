import {
  GET_SUBMISSION,
  GET_SUBMISSION_SUCCESS,
  GET_SUBMISSION_ERROR,
  SUBMISSION_POST,
  SUBMISSION_POST_SUCCESS,
  SUBMISSION_POST_ERROR
} from './../actions';

const initState = {
  submissions: [],
  error: false,
  message: '',
  loading: false
}
export default (state = initState, action) => {
  switch (action.type) {
    case GET_SUBMISSION:
      return {...state, loading: true, error: ''};
    case GET_SUBMISSION_SUCCESS:
      return {...state, loading: false, subscribers: action.payload.data, message: action.payload.message};
    case GET_SUBMISSION_ERROR:
      return {...state, loading: false, message: action.payload, error: true};

      case SUBMISSION_POST:
        return {...state, loading: true, error: '', message:''};
      case SUBMISSION_POST_SUCCESS:
        return {...state, loading: false, message:"Submission Add Successfully", error: false};
      case SUBMISSION_POST_ERROR:
        return {...state, loading: false, message: "Submission Went Wrong", error: true};
  

    default:
      return {...state};
  }
}
