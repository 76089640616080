import {
  RATE_CALCULATIONS,
  RATE_CALCULATIONS_SUCCESS,
  RATE_CALCULATIONS_ERROR
} from '../actions';

const initState = {
  error: false,
  message: '',
  loading: false,
  rateCalculateval:[]
}
export default (state = initState, action) => {
  switch (action.type) {
    case RATE_CALCULATIONS:
      return {...state, loading: true, error: '', message:''};
    case RATE_CALCULATIONS_SUCCESS:
      return {...state, loading: false, rateCalculateval:action.payload.data.data, message:"Calculate Successfully", error: false};
    case RATE_CALCULATIONS_ERROR:
      return {...state, loading: false, message: "Error! Rate Calculation", error: true};

    default:
      return {...state};
  }
}
