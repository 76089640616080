import React from 'react'

const SuccessPage = () => {
    return (

        <a href="https://www.nurse2nursestaffing.com/">
            <h5 className="successfulRedirect">
                Successfully submitted Skills Checklist.
            </h5>
            <h6 className="successfulRedirect">
               ⬅ Click here to go back.
            </h6>
        </a>
    )
}

export default SuccessPage
