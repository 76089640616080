import React from 'react'
import ReactDOM from 'react-dom'
import Avatar from 'react-avatar-edit';
import {Button, Row, Col} from 'shards-react';
import axios from 'axios'

export default class App extends React.Component {

  constructor(props) {
    super(props)
    const src =  require('./../../images/avatars/avatar-placeholder.png')
    this.state = {
      preview: null,
      src
    }
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
  }
  
  onClose() {
    this.setState({preview: null})
}

onCrop(preview,file) {
    this.setState({preview : preview})
    console.log(preview)
    console.log(file)
}

onBeforeFileLoad(elem) {
    if(elem.target.files[0].size > 4000000){
        alert("File is too big!");
        elem.target.value = "";
    };

}
setEditorRef = (editor) => {
    if (editor) {
        this.editor = editor;
        const img = this.editor;
        console.log(img);
    }
}


dataURLtoFile(dataurl, filename) {
console.log(dataurl)
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}

CallApi=(MyData)=>{
  axios({
    method: 'post',
    
    url: "https://api.n2ns.app/microservices/api/profile/change-avatar",
    data: MyData,
    headers: {
        Authorization: 'Bearer' + ' ' + localStorage.getItem('ats_user_token'),
    }, config: {
        headers: {"Content-Type": "multipart/form-data"}
    }
}).then((data) => {
  this.props.closeModal(false)
 
    return (data)
}).catch((error) => {
  this.props.closeModal(false)
    return (error)
})
}

handlesubmit =async() => {
  if(this.state.preview){
   
      var file = this.dataURLtoFile(this.state.preview,'n2n.jpg');
      console.log('image',file);
      const MyData = new FormData();
      MyData.append( 'avatar',file
      );
      console.log('avatar',MyData)
       this.CallApi(MyData)
      // this.props.postprofieavatar(MyData)

  }else{  
      console.log( "Please, Select an Image."); 
    
  }

}
 
  render () {
    return (
      <div>
        <Avatar
         ref={(ref) => this.setEditorRef(ref)}
         width={390}
         height={295}
         onCrop={this.onCrop}
         onClose={this.onClose}
         onBeforeFileLoad={this.onBeforeFileLoad}
        //  src={this.state.src}
         closeIconColor={''}
         minCropRadius={80}
        />
        <Row>
          <Col>
        <Button className="mt-2 float-right" onClick={()=>this.handlesubmit()}>Update Image</Button>
        </Col>
        </Row>
          
      </div>
    )
  }
}
