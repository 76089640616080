// import axios from '../../utils/Api'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {GET_APPLICANTS_LIST,NOTES_POST} from '../actions';
import qs from 'qs';
import {
  getApplicantsListSuccess,
  getApplicantsListError,
  notesSubmitSuccess,
  notesSubmitError
} from './actions';
import axios from 'axios';

export function* watchGetApplicants() {
  yield takeEvery(GET_APPLICANTS_LIST, getApplicantsNow);
}
 
// var config = {
//   method: 'get',
//   url: 'http://192.168.100.108:3000/api/candidate/list/',
//   };
// const getrecruiter = (state) => state.Auth.authUser.email

const getApplicantsNowAsync = async (recuiter1,recuiter2) =>

  await axios.get(`https://api.n2ns.app/microservices/api/candidate/list/${recuiter1}%20${recuiter2}`)
  .then(function (response) {
  console.log(response);
  return response;
  })
  .catch(function (error) {
  console.log(error);
  });



  // await axios.get(`candidate/list?${recuiter}`
  // ).then(({data}) => {
  //   console.log("Applicants data",data);
  //   return data;
  // }).catch(function (error) {
   
 
  //   return error.response.data.message;
  // });

function* getApplicantsNow({payload}) {
  try {

    const recuiter1 = localStorage.getItem("recuiter")
    const recuiter2 = localStorage.getItem("recuiterLastName")
    
     const res1 = recuiter1.charAt(0).toUpperCase() + recuiter1.slice(1)
     const res2 = recuiter2.charAt(0).toUpperCase() + recuiter2.slice(1)
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("ats_user_token");
    const response = yield call(getApplicantsNowAsync,res1,res2);
   
    if (response.data.status) {
      
      yield put(getApplicantsListSuccess(response.data));
    } else {
     
      yield put(getApplicantsListError(response));
    }
  } catch (error) {
    
    console.log(error);
    yield put(getApplicantsListError(error.message));

  }
}




var config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("ats_user_token")}`,
    "Content-Type": "application/json"
  }
};
const uri = `https://api.n2ns.app/microservices/api/candidate/addNotes`;


export function* watchAddNotes() {
  yield takeEvery(NOTES_POST, addNotesNow);
}

const addNotesNowAsync = async (values) =>
await axios
.post(uri, values, config)
.then((response) => {
    console.log(response);
    return response;
  }).catch(function (error) {
    console.log(error);
    return error.response;
  });


function* addNotesNow({payload}) {
  console.log(payload.values);
  // localStorage.setItem("email",email);
  try {
    const response = yield call(addNotesNowAsync, payload.values);
    console.log(response)
    if (response.data.status) {
      
      yield put(notesSubmitSuccess(response.data));
    } else {
      yield put(notesSubmitError(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(notesSubmitError("Network Error"));

  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNotes),
    fork(watchGetApplicants)
  ]);
}
