import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR ,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_RESOLVED,
  CLEAR_PASSWORD,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_OUT
} from './../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: {user}
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload:  message

});

export const changePassword = (data) => ({
  type: CHANGE_PASSWORD,
  payload: { data }
});

export const changePasswordResolved = (msg,type) => ({
  type: CHANGE_PASSWORD_RESOLVED,
  payload: {msg,type}
});
export const clearAlert = () => ({
  type: CLEAR_PASSWORD
});

export const getProfile = () => ({
  type: GET_USER_PROFILE
});
export const getProfileSuccess = (data) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload:{data}
});


export const getOut = (data) => ({
  type: GET_OUT,
  payload:{data}
});
/*export const verification = (user, history) => ({
  type: VERIFICATION,
  payload: { user, history }
});

export const verificationSuccess = (user) => ({
  type: VERIFICATION_SUCCESS,
  payload: user
});
export const verificationError = (message1) => ({
  type: VERIFICATION_ERROR,
  payload:  message1

});



export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = ({resetPasswordCode,newPassword,history}) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode,newPassword,history }
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});



export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const sessionTimeOut = (history) => ({
  type: SESSION_TIMEOUT,
  payload: { history }
});*/
