import {
  RATE_CALCULATIONS,
  RATE_CALCULATIONS_SUCCESS,
  RATE_CALCULATIONS_ERROR } from '../actions';

  export const rateCalculation  = (values) => ({
    type: RATE_CALCULATIONS,
    payload: {values}
  });
  
  export const rateCalculationSuccess = (message) => ({
    type: RATE_CALCULATIONS_SUCCESS,
    payload: message
  });
  export const rateCalculationError = (message) => ({
    type: RATE_CALCULATIONS_ERROR,
    payload:  message
  });
