import React,{useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import { connect } from "react-redux";
import {getProfile} from '../redux/auth/actions'

class UserProfileLite extends React.Component  {
  
  componentDidMount(){
    this.props.getProfile()
  }
render(){
  
return(

  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <Row>
      <Col lg="4">
        <UserDetails  userDetails={this.props.Profile}/>
      </Col>
      <Col lg="8">
        <UserAccountDetails userDetails={this.props.Profile}/>
      </Col>
    </Row>
  </Container>
)
}
};

const mapStateToProps = ({Auth}) => {
  const {Profile} = Auth;
  return {Profile};
};

export default connect(
  mapStateToProps,
  {
    getProfile
  }
)(UserProfileLite); 
