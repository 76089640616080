import {
  GET_HISTORY_LIST,
  GET_HISTORY_LIST_SUCCESS,
  GET_HISTORY_LIST_ERROR,
  GET_HISTORY_PDF,
  GET_HISTORY_PDF_SUCCESS,
  GET_HISTORY_PDF_ERROR,
  DELETE_HISTORY,
  DELETE_HISTORY_SUCCESS,
  DELETE_HISTORY_ERROR } from '../actions';


  //get list of skills history
export const getHistoryList = () => ({
  type: GET_HISTORY_LIST
});

export const getHistoryListSuccess = (history) => ({
  type: GET_HISTORY_LIST_SUCCESS,
  payload: history
});

export const getHistoryListError = (message) => ({
  type: GET_HISTORY_LIST_ERROR,
  payload:  message

});


// Get Pdf
export const getPdf = (id) => ({
  type: GET_HISTORY_PDF,
  payload: {id}
});

export const getPdfSuccess = (history) => ({
  type: GET_HISTORY_PDF_SUCCESS,
  payload: history
});

export const getPdfError = (message) => ({
  type: GET_HISTORY_PDF_ERROR,
  payload:  message

});

//Delete History
export const deleteHistory = (id) => ({
  type: DELETE_HISTORY,
  payload: {id}
});

export const deleteHistorySuccess = (history) => ({
  type: DELETE_HISTORY_SUCCESS,
  payload: history
});

export const deleteHistoryError = (message) => ({
  type: DELETE_HISTORY_ERROR,
  payload:  message

});


