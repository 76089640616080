import {
  SUBMISSION_POST,
  SUBMISSION_POST_SUCCESS,
  SUBMISSION_POST_ERROR,
  GET_SUBMISSION,
  GET_SUBMISSION_SUCCESS,
  GET_SUBMISSION_ERROR
} from './../actions';


  //get Submissions
export const getSubmissions = (pageNo,perPage,recuiter) => ({
  type: GET_SUBMISSION,
  payload:{pageNo,perPage,recuiter}
});

export const getSubmissionsSuccess = (submissions) => ({
  type: GET_SUBMISSION_SUCCESS,
  payload: submissions
});

export const getSubmissionsError = (message) => ({
  type: GET_SUBMISSION_ERROR,
  payload:  message

});

// Post Submissions
export const addSubmissions  = (values) => ({
  type: SUBMISSION_POST,
  payload: {values}
});

export const addSubmissionsSuccess = (message) => ({
  type: SUBMISSION_POST_SUCCESS,
  payload: message
});
export const addSubmissionsError = (message) => ({
  type: SUBMISSION_POST_ERROR,
  payload:  message

});
