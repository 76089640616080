import {
  GET_HISTORY_LIST,
  GET_HISTORY_LIST_SUCCESS,
  GET_HISTORY_LIST_ERROR,
  GET_HISTORY_PDF,
  GET_HISTORY_PDF_SUCCESS,
  GET_HISTORY_PDF_ERROR,
  DELETE_HISTORY,
  DELETE_HISTORY_SUCCESS,
  DELETE_HISTORY_ERROR 
} from './../actions';

const initState = {
  history: [],
  DeletedRecord:[],
  error: false,
  message: '',
  loading: false,
  pdf:""
}
export default (state = initState, action) => {
  
  switch (action.type) {
    case GET_HISTORY_LIST:
      return {...state, loading: true, error: ''};
    case GET_HISTORY_LIST_SUCCESS:
      return {...state, loading: false, history: action.payload, message: "Success"};
    case GET_HISTORY_LIST_ERROR:
      return {...state, loading: false, message: action.payload, error: true};

      case GET_HISTORY_PDF:
        return {...state, loading: true, error: ''};
      case GET_HISTORY_PDF_SUCCESS:
        return {...state, loading: false, pdf: action.payload, message: "Success"};
      case GET_HISTORY_PDF_ERROR:
        return {...state, loading: false, message: action.payload, error: true};
  
        case DELETE_HISTORY:
          return {...state, loading: true, error: ''};
        case DELETE_HISTORY_SUCCESS:
          return {...state, loading: false, DeletedRecord: action.payload, message: action.payload.message,error:false};
        case DELETE_HISTORY_ERROR:
          return {...state, loading: false, message: "Error Deleted", error: true};
    

    default:
      return {...state};
  }
}
