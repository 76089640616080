import {
  GET_JOB_LIST,
  GET_JOB_LIST_SUCCESS,
  GET_JOB_LIST_ERROR,
  GET_UNKNOWN_SUBMODS_LIST,
  GET_UNKNOWN_SUBMODS_LIST_SUCCESS,
  GET_UNKNOWN_SUBMODS_LIST_ERROR,
  ADD_VALID_SUBMOD,
  ADD_VALID_SUBMOD_SUCCESS,
  ADD_VALID_SUBMOD_ERROR,
} from './../actions';


export const addValidSubmod  = (values) => ({
  type: ADD_VALID_SUBMOD,
  payload: {values}
});

export const addValidSubmodSuccess = (message) => ({
  type: ADD_VALID_SUBMOD_SUCCESS,
  payload: message
});
export const addValidSubmodError = (message) => ({
  type: ADD_VALID_SUBMOD_ERROR,
  payload:  message

});

export const getJobsList = () => ({
  type: GET_JOB_LIST
});

export const getJobsListSuccess = (jobs) => ({
  type: GET_JOB_LIST_SUCCESS,
  payload: jobs
});

export const getJobsListError = (message) => ({
  type: GET_JOB_LIST_ERROR,
  payload:  message

});

export const getUnknownSubMods = () => ({
  type: GET_UNKNOWN_SUBMODS_LIST
});

export const getUnknownSubModsSuccess = (submods) => ({
  type: GET_UNKNOWN_SUBMODS_LIST_SUCCESS,
  payload: submods
});

export const getUnknownSubModsError = (message) => ({
  type: GET_UNKNOWN_SUBMODS_LIST_ERROR,
  payload:  message

});
