import {
  APPLICATION_POST,
  APPLICATION_POST_SUCCESS,
  APPLICATION_POST_ERROR } from '../actions';

  export const applicationSubmit  = (values) => ({
    type: APPLICATION_POST,
    payload: {values}
  });
  
  export const applicationSubmitSuccess = (message) => ({
    type: APPLICATION_POST_SUCCESS,
    payload: message
  });
  export const applicationSubmitError = (message) => ({
    type: APPLICATION_POST_ERROR,
    payload:  message
  });
