import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_HISTORY_LIST,
  GET_HISTORY_PDF,
  DELETE_HISTORY
} from "../actions";
import qs from "qs";
import {
  getHistoryListSuccess,
  getHistoryListError,
  getPdfSuccess,
  getPdfError,
  deleteHistorySuccess,
  deleteHistoryError
} from "./actions";
import download from "downloadjs";

import Moment from "moment";
export function* watchGetHistory() {
  yield takeEvery(GET_HISTORY_LIST, getHistoryNow);
}
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDgyZjNjNTIxMmFlZTA5YTBjY2EyNDIiLCJlbWFpbCI6ImplcmVteUBlbWFpbC5jb20iLCJpYXQiOjE2MTkxOTQ4MjgsImV4cCI6MTYxOTIyMzYyOH0.qD-1WCsVgQSkCD3ubB7XeL6V18wpSm-uGGxrZ1nptts";

// const getrecruiter = (state) => state.Auth.authUser.email
const getHistoryNowAsync = async () =>
  await axios
    .get(`https://api.n2ns.app/microservices/api/NurseInformation/All`, {
      headers: { Authorization: "Bearer " + token }
    })
    .then(({ data }) => {
      console.log(data)
      return data;
    })
    .catch(function(error) {
      console.log(error);
      return error.response.data.message;
    });

function* getHistoryNow() {
  try {
    const response = yield call(getHistoryNowAsync);
    if (response) {
      yield put(getHistoryListSuccess(response));
    } else {
      yield put(getHistoryListError(response));
    }
  } catch (error) {
    console.log(error);
    yield put(getHistoryListError(error.message));
  }
}

//get Pdf
export function* watchGetPdf() {
  yield takeEvery(GET_HISTORY_PDF, getPdfNow);
}

const getPdfNowAsync = async id =>
  await axios
    .get(`https://api.n2ns.app/microservices/api/NurseInformation/${id}`, {
      headers: { Authorization: "Bearer " + token }
    })
    .then(({ data }) => {
      console.log(data)
      return data;
    })
    .catch(function(error) {
      console.log(error);
      return error.response.data.message;
    });

function* getPdfNow({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(getPdfNowAsync, id);
    if (response) {
      var date = Moment(response.data.createdAt).format('MMMM Do, YYYY')
      download(
        `data:application/pdf;base64,${response.data.testDataPdf.substring(
          51
        )}`,
          `${response.data.firstName}${response.data.lastName}-${response.data.skillsTestName}-${date}`,
        "application/pdf"
      );

      //  window.open(response.data.testDataPdf, 'pdf')

      yield put(getPdfSuccess(response));
    } else {
      yield put(getPdfError(response));
    }
  } catch (error) {
    console.log(error);
    yield put(getPdfError(error.message));
  }
}

//Delete History
export function* watchDeleteHistory() {
  yield takeEvery(DELETE_HISTORY, deleteHistoryNow);
}

const deleteHistoryNowAsync = async id =>
  await axios
    .delete(`https://api.n2ns.app/microservices/api/NurseInformation/${id}`, {
      headers: { Authorization: "Bearer " + token }
    })
    .then(({ data }) => {
      console.log("response",data)
      return data;
    })
    .catch(function(error) {
      console.log(error);
      return error.response.data.message;
    });

function* deleteHistoryNow({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteHistoryNowAsync, id);
    if (response.status == 1) {
      console.log(response)
      yield put(deleteHistorySuccess(response));
    } else {
      yield put(deleteHistoryError(response));
    }
  } catch (error) {
    console.log(error);
    yield put(deleteHistoryError(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetHistory),
    fork(watchGetPdf),
    fork(watchDeleteHistory)
  ]);
}
