import React,{useEffect, useState}from "react";
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from "prop-types";
import {getProfile} from '../../redux/auth/actions'

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import _ from 'lodash';
import { store } from 'react-notifications-component';
import axios from 'axios';
const URI = 'https://api.n2ns.app/microservices/api/profile/update'
var config = {
  headers: {
    'Authorization': `Bearer ${localStorage.getItem("ats_user_token")}`,
    'Content-Type': 'application/json'
}
};
const  UserAccountDetails =()=>{
  const profile = useSelector(state=>state.Auth)
  const dispatch = useDispatch()
  const [Profile, setProfile] = useState([])

  useEffect(()=>{
    if(profile && profile.Profile)
    setProfile(profile.Profile)
  },[profile])

  const onChange =(e,name)=>{
   setProfile({...Profile,[name]:e.target.value})
  }
const ApiCall=async ()=>{
await axios.post(URI,Profile,config).then(({data})=>{
  store.addNotification({
    title: "Success!",
    message: 'Profile Updated Successfuly',
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
  dispatch(getProfile())
}).catch(e=>{
  store.addNotification({
    title: "OOps!",
    message: 'Something went wrong',
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
})
}
const submitProfile = async (e)=>{
  e.preventDefault()
  await ApiCall()
}
  return(

  <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">User Account Details </h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form  onSubmit={submitProfile}>
              <Row form>
                {/* First Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feFirstName">First Name</label>
                  <FormInput
                    id="feFirstName"
                    placeholder="First Name"
                    value={Profile && Profile.firstName ? Profile.firstName:""}
                    onChange={(e)=>onChange(e,'firstName')}

                  />
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feLastName">Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    value={Profile && Profile.lastName ? Profile.lastName:""}
                    onChange={(e)=>onChange(e,'lastName')}
                  />
                </Col>
              </Row>
              <Row form>
              <Col md="6" className="form-group">
                  <label htmlFor="feOfficeNumber">Office Number#</label>
                  <FormInput
                    id="feOfficeNumber"
                    placeholder="Office Number#"
                    value={Profile && Profile.office ? Profile.office:""}
                    onChange={(e)=>onChange(e,'office')}
                  />
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feCellNumber">Cell Number#</label>
                  <FormInput
                    id="feCellNumber"
                    placeholder="Cell Number#"
                    value={Profile && Profile.cell ? Profile.cell:""}
                    onChange={(e)=>onChange(e,'cell')}
                  />
                </Col>
              </Row>
              <Row form>
                {/* Email */}
                <Col md="6" className="form-group">
                  <label htmlFor="feEmail">Email</label>
                  <FormInput
                    type="email"
                    id="feEmail"
                    placeholder="Email Address"
                    value={Profile && Profile.email ? Profile.email:""}
                  />
                </Col>
                {/* Password */}
                <Col md="6" className="form-group">

                </Col>
              </Row>

              <Row form>
                {/* Description */}
                <Col md="12" className="form-group">
                  <label htmlFor="feDescription">Description</label>
                  <FormTextarea id="feDescription" rows="5"
                   value={Profile && Profile.description ? Profile.description:""}
                   onChange={(e)=>onChange(e,'description')}
                  />
                </Col>
              </Row>
              <Button theme="accent" type="submit">Update Account</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
)};
const mapStateToProps = ({Auth}) => {
  const {Profile} = Auth;
  return {Profile};
};

export default UserAccountDetails;


